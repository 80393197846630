<template>
  <!-- Start Containerbar -->

  <div id="containerbar" data-app>
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("remission.remission") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("remission.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"> {{ $t("remission.requisition.remission") }} </a>
                </li>
                <li @click="changeAction(1)" class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li v-if="titleAction != ''" class="breadcrumb-item">
                  <a href="#">{{ $t(titleAction) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
              class="btn btn-outline-success"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div v-bind:class="[action != 1 ? 'card border-none-b' : 'card']">
              <AlertMessageComponent ref="alertRemmision" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <h5 v-if="titleAction != ''" class="card-title ">
                      <i class="feather icon-log-in mr-2"></i
                      >{{ $t(titleAction) }}
                      <v-chip
                        class="ma-2"
                        v-if="modelRemission.idRemmision"
                        color="#263a5b"
                      >
                        {{
                          modelRemission.idRemmision +
                            " - " +
                            modelRemission.remissionkey
                        }}
                      </v-chip>
                    </h5>
                    <h5 v-else class="card-title">
                      <i class="feather icon-log-in mr-2"></i>{{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <RemissionTableComponent
                v-if="showTable"
                v-show="showAction(1)"
                :headers="headers"
                :modelTable="listRemission"
                :pagesAtributes="pagesAtributes"
                :methodPage="listAllRemmisions"
                :remissionType="remissionType"
                :isButton="true"
                @excelTable="excelTable"
                @downloadPDF="downloadPDF"
                @detail="detail"
                :changeAction="changeAction"
                :isPDF="withPermissionName(permissions.REM_TRAN_DL_PDF_03)"
                :isDetail="withPermissionName(permissions.REM_TRAN_DETA_01)"
                :isAddProduct="
                  withPermissionName(permissions.REM_TRAN_ADD_PROD_02)
                "
                :isUpdateProduct="
                  withPermissionName(permissions.REM_TRAN_EDIT_PROD_05)
                "
                :isFilter="false"
              />
              <div v-if="showAction(5)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_info") }}
                  </h6>
                </div>
                <RemissionFormComponent
                  v-bind:modelRemission="modelRemission"
                  :loading="loading"
                  ref="remissionForm"
                />
                <ButtonAction
                  :onClick="onClick"
                  :buttonTitle="buttonTitle"
                  :validator="false"
                  :sending="false"
                />
              </div>
              <div v-if="showAction(3)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1"> mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeaderUpdate"
                  v-bind:modelChange="modelChange"
                />
                <div class="col-12 pt-5 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-2-box-outline</v-icon>
                    Información del producto
                  </h6>
                </div>
                <RemissionAddProductFormComponent
                  v-bind:modelProduct="modelProduct"
                  :alert="alert"
                  :productTableList="productTableList"
                  :addProductListComponent="addProductUpdate"
                  ref="remissionAddProductFormComponent"
                />
              </div>
              <div v-if="showAction(4)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeaderUpdate"
                  :editProduct="true"
                  v-on:openModalEdit="openModalUpdateProduct"
                  v-bind:modelChange="modelChange"
                />
                <div class="pb-15"></div>
                <RemissionModalUpdateProductComponent
                  ref="RemissionModalUpdateProductComponent"
                  v-bind:modelProduct="modelProduct"
                  v-bind:dialogM="dialogM"
                  :modelRemission="modelRemission"
                  :currentItem="currentItem"
                  :alert="alert"
                  :originId="branchOffice"
                  :getProductRemission="getProductRemission"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RemissionsModalDetail
      :model="model"
      :dialogM="dialogM"
      :remmisionViewHeaders="remmisionViewHeaders"
    />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import { ExcelRequest } from "@/core/request";
import { RemissionRequest } from "@/core/request";

/* Componetes de Remisión NEW */
import RemissionAddProductFormComponent from "@/components/remission/RemissionAddProductFormComponent";
import RemissionFormComponent from "@/components/remission/RemissionFormComponent";
import RemissionModalUpdateProductComponent from "@/components/remission/RemissionModalUpdateProductComponent";
import RemissionTableProductComponent from "@/components/remission/RemissionTableProductComponent";
import { SwalConfirm } from "@/core/SwalAlert";
import ButtonAction from "@/common/button/ButtonAction";

/* Componetes de Remisión */

import RemissionsModalDetail from "@/components/remission/RemissionsModalDetail";
import RemissionTableComponent from "@/components/remission/RemissionTableComponent";
import RemissionModalChangeProductComponent from "@/components/remission/RemissionModalChangeProductComponent";
import {
  REMISSION_TRANSFER,
  CREATE_REMMISION,
  REMMISION_VIEW,
  CREATE_REMMISION_UPDATE,
} from "@/core/DataTableHeadersLocale";
import moment from "moment";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import SelectBranchOffice from "@/common/select/SelectBranchOffice";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      title: "remission.remission_transfer",
      remmisionHeaders: REMISSION_TRANSFER(),
      remmisionViewHeaders: REMMISION_VIEW(),
      productsHeader: CREATE_REMMISION(),
      productsHeaderUpdate: CREATE_REMMISION_UPDATE(),
      headers: {},
      /* New variables */
      modelRemission: {
        idRemmision: "",
        suppliers: "",
        invoice: "",
        remissionkey: "",
        requisitionGobId: "",
        remissionType: "",
      },
      modelChange: {
        remmisionViewList: [],
      },
      modelProduct: {},
      productTableList: [],
      produdctsRemmision: [],
      currentItem: {},
      action: 1,
      remissionType: 2,
      dialogM: {
        dialog: false,
        dialogUpdate: false,
        dialogRemmisionChange: false,
      },
      originId: this.branchOffice,
      model: {
        userBranchOfficeDTO: {
          user: {
            name: "",
          },
        },
      },
      loading: false,
      permissions: PermissionConstants,
      /* Pagination */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
      },
      listRemission: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,
    };
  },
  computed: {
    ...mapState("auth", ["branchOffice", "actionState"]),
    titleAction() {
      if (this.action == 2) {
        return "remission.remission_registration";
      } else if (this.action == 3 || this.action == 4) {
        return "remission.remission_update";
      }
      return "";
    },
    buttonTitle() {
      return this.action == 2
        ? "Registrar entrada"
        : this.action == 3
        ? "Modificar productos"
        : this.action == 4
        ? "Cambio de remisión"
        : this.action == 5
        ? "Modificar información"
        : "";
    },
  },
  methods: {
    withPermissionName(permissions) {
      return UtilFront.withPermissionName(permissions);
    },
    ...mapActions({
      getListProducts: "remission/getListProducts",
    }),
    ...mapMutations({
      updateAction: "auth/UPDATE_ACTION",
      listAllProducts: "remission/GET_PRODUCTS",
      setFiscalFound: "remission/SET_FISCAL_FOUND",
      listAllRequisitionGob: "remission/SET_REQUISITION",
      listAllSuppliers: "remission/SET_SUPPLIER",
    }),
    alert(type, message) {
      this.$refs.alertRemmision.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    openModalUpdateProduct(item) {
      this.modelProduct = {};

      this.currentItem = item;
      this.$refs.RemissionModalUpdateProductComponent.setData(item);
      this.modelProduct.idProductRemmision = item.idProductRemmision;
      this.modelProduct.productId = item.products.idProduct;
      this.modelProduct.quantity = item.quantity;
      this.modelProduct.idFiscalFund = item.fiscalFund.idFiscalFund;
      this.modelProduct.unitPrice = item.unitPrice;
      this.modelProduct.lot = item.lot;
      this.modelProduct.expirationDate = moment(
        item.expirationDate,
        "DD/MM/YYYY"
      ).format("yyyy-MM-DD");
      this.modelProduct.idStock = item.idStock;
      this.dialogM.dialogUpdate = true;
    },
    async changeAction(action, item) {
      switch (action) {
        case 1:
          this.modelRemission.requisitionGobId = this.modelRemission.remissionkey = this.modelRemission.invoice = this.modelRemission.suppliers = this.modelRemission.idRemmision =
            "";
          this.productTableList = [];
          this.produdctsRemmision = [];
          this.modelChange.remmisionViewList = [];
          this.action = action;
          break;
        case 2:
          this.modelProduct = {};
          this.action = action;
          break;
        case 3:
        case 4:
        case 6:
          this.modelProduct = {};
          await this.getProductRemission(item, action);
          break;
        case 5:
          await this.getInfoRemission(item, action);
          break;
      }
    },
    async addProductUpdate(item, products) {
      const found = this.productTableList.find(
        (element) =>
          element.lot.trim().toUpperCase() == item.lot.trim().toUpperCase() &&
          item.productKey == element.products.productKey
      );
      if (found != undefined) {
        const { isConfirmed } = await SwalConfirm.fire({
          html:
            "Se ha encontrado un producto en la entrada con la misma información que intenta ingresar:" +
            " <br><br>Causes:<b> " +
            item.productKey +
            "</b> <br>Lote:<b> " +
            item.lot.trim().toUpperCase() +
            "</b>",
          icon: "warning",
          confirmButtonText:
            '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
        });
        if (!isConfirmed) {
          return;
        }
      }

      const { isConfirmed } = await SwalConfirm.fire({
        title:
          "El producto con la siguiente información sera agregado a la entrada. ¿Estás seguro? ",
        html:
          "<div class='row'>" +
          "<div class='col-md-12 pb-0'><b>" +
          "Causes: </b>" +
          item.productKey +
          "</div>" +
          "<div class='col-md-12 pb-0'><b>" +
          "Caducidad: " +
          "</b>" +
          item.expirationDate +
          "</div>" +
          "<div class='col-md-12  pb-0'><b>" +
          "Fuente: " +
          "</b>" +
          item.fiscalfund +
          "</div>" +
          "<div class='col-md-12  pb-0'><b>" +
          "Cantidad: " +
          "</b>" +
          item.quantity +
          "</div>" +
          "<div class='col-md-12'><b>" +
          "Lote: " +
          "</b>" +
          item.lot +
          "</div>" +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }

      this.loading = UtilFront.getLoadding();
      let params = {
        expirationDate: products.expirationDate,
        fiscalFund: {
          idFiscalFund: products.fiscalFund.idFiscalFund,
        },
        lot: item.lot,
        products: {
          idProduct: products.products.idProduct,
        },
        remissionType: 2,
        quantity: item.quantity,
        remmisionId: this.modelRemission.idRemmision,
        unitPrice: products.unitPrice,
        userBranchOffice: {
          idUser: this.user.idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      RemissionRequest.addProductInRemmision(params)
        .then((response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Add_Product_0005");
            this.$refs.remissionAddProductFormComponent.clearAddProduct();
            this.reloadProductUpdate();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
          //Cierro pantalla de carga
          this.loading.hide();
        });
    },
    async reloadProductUpdate() {
      this.loading = UtilFront.getLoadding();
      let params = {
        remissionType: 2,
        accion: this.action == 3 ? 1 : this.action == 4 ? 2 : 0,
        idRemmision: this.modelRemission.idRemmision,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getProductRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.productTableList = data;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async getProductRemission(item, action) {
      this.loading = UtilFront.getLoadding();
      let params = {
        remissionType: 2,
        accion: action == 3 ? 1 : action == 4 ? 2 : 0,
        idRemmision: item.idRemmision,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getProductRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.modelRemission.idRemmision = item.idRemmision;
            this.modelRemission.remissionkey = item.remissionKey;
            this.productTableList = data;
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async getInfoRemission(item, action) {
      this.loading = UtilFront.getLoadding();
      let params = {
        idRemmision: item.idRemmision,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getInfoRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.modelRemission = {
              idRemmision: item.idRemmision,
              suppliers: 0,
              invoice: data.invoice,
              remissionkey: data.remissionkey,
              requisitionGobId: data.requisitionGobId,
            };
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async onClick() {
      switch (this.action) {
        case 2:
          var isValid = await this.$refs.remissionForm.validateForm();
          if (this.produdctsRemmision.length == 0) {
            this.alert("error", "Error_Product_Add_Remmision_0001");
          } else {
            if (isValid) {
              //Guardo la remision
              this.addRemission();
            } else {
              this.alert("error", "Empty_Fields_0001");
            }
          }
          break;
        case 5:
          let isValidInfo = await this.$refs.remissionForm.validateForm();
          if (isValidInfo) {
            //Modificar la remision
            this.updateRemmision();
          } else {
            this.alert("error", "Empty_Fields_0001");
          }
          break;
        default:
          break;
      }
    },
    async updateRemmision() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información de la entrada sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let data = {
        idRemmision: this.modelRemission.idRemmision,
        remissionkey: this.modelRemission.remissionkey,
        invoice: this.modelRemission.invoice,
        supplier: {
          idSupplier:
            this.modelRemission.suppliers === undefined
              ? ""
              : this.modelRemission.suppliers,
        },
        userBranchOfficeDTO: {
          idUser: this.user.idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      RemissionRequest.updateRemmision(data)
        .then(async (response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Update_Success_0001");
            this.changeAction(1);
            await this.listAllRemmisions(this.pagesAtributes);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    addProductListComponent(productTableList, produdctsRemmision) {
      this.productTableList.push(productTableList);
      this.produdctsRemmision.push(produdctsRemmision);
      this.$refs.remissionAddProductFormComponent.clearAddProduct();
    },
    async deleteRecordRemmi(item) {
      let currentIndex = this.productTableList.findIndex(
        (element) => element.id == item.item.id
      );
      this.productTableList.splice(currentIndex, 1);
      this.produdctsRemmision.splice(currentIndex, 1);
    },
    async listAllRemmisions(page) {
      this.loading = UtilFront.getLoadding();
      await RemissionRequest.listAllRemmisionByBranch({
        type: this.remissionType,
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
        page,
      })
        .then((response) => {
          console.log(response);
          let { data } = response;
          this.listRemission = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    detail(item) {
      this.loading = UtilFront.getLoadding();
      RemissionRequest.remmisionGetWithProductsRemmision({
        idRemmision: item.idRemmision,
        remissionType: 2,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      })
        .then((res) => {
          console.log(res);
          let { data, success, message } = res.data.data;
          if (success) {
            this.model = data;
            //this.model.suppliersName = item.supplier.name;
            this.model.totalProducts = this.model.produdctsRemmision
              .map((obj) => {
                return obj.quantity;
              })
              .reduce((a, b) => {
                return a + b;
              }, 0);
            this.dialogM.dialog = true;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idRemision=" + item.idRemmision + "&" + "idSucursal=" + this.branchOffice.idBranchoffice;
      ExcelRequest.reportRemmisionPdf(send);
      this.$toast.success("Documento generado (documento.pdf)");
    },
    excelTable() {
      this.$toast.success("Reporte generado (documento.xlsx)");
      ExcelRequest.reportRemmisions({
        remissionType: this.remissionType,
        userBranchOfficeDTO: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: UtilFront.getDataUser().idUser,
        },
      });
    },
  },
  async created() {
    this.user = UtilFront.getDataUser();
    this.dialogM.dialog = false;
    this.headers = this.remmisionHeaders;
    this.remissionType = 2;
    // Actualiza la sucursal para el componente
    this.updateAction(1);
    /* Lista GET_PRODUCTS: Produtos */
    await this.getListProducts();
    /* List SET_REQUISITION: Pedidos */
    this.listAllRequisitionGob(this.branchOffice.idBranchoffice);
    /* List SET_SUPPLIER: Provedor */
    this.listAllSuppliers();
  },
  watch: {
    // En caso de que cambie la sucursal actualiza la tabla
    async branchOffice() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
      };
      await this.listAllRemmisions(this.pagesAtributes);
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.productsHeader = CREATE_REMMISION();
      this.headers = REMMISION();
      this.remmisionViewHeaders = REMMISION_VIEW();
    },
  },
  components: {
    AlertMessageComponent,
    RemissionTableComponent,
    SelectBranchOffice,
    ButtonPrintComponent,
    RemissionsModalDetail,
    RemissionAddProductFormComponent,
    RemissionFormComponent,
    RemissionTableProductComponent,
    ButtonAction,
    RemissionModalUpdateProductComponent,
    RemissionModalChangeProductComponent,
  },
};
</script>
